export interface FeaturesState {
  config: FeaturesConfig;
}

export interface FeaturesConfig {
  providerSelection: FeaturesProviderSelection;
  providerUserChangeAllowed: boolean;
}

export enum FeaturesProviderSelection {
  MANUAL = "manual",
  AUTOMATED = "automated",
}
