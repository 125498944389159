import { Loader } from "../icons/Loader";
type Props = {
  color?: string;
  size?: string;
};
const LoadingIndicator = ({ size = "24", color }: Props) => {
  return (
    <div className={`w-${size} h-${size} animate-spin flex items-center justify-center m-0`}>
      <Loader color={color} />
    </div>
  );
};

export default LoadingIndicator;
