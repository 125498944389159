import { Language } from "../store/redux/settings/types";

export const detectClientLanguage = (): Language => {
  const userLocale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
  if (!userLocale) {
    return Language.EN;
  }
  if (userLocale.indexOf("ro") === 0) {
    return Language.RO;
  }
  return Language.EN;
};
