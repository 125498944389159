import { ReduxState } from "@/store/types";

const timezone = (state: ReduxState): string | undefined => {
  return state.settings.timezone;
};

const language = (state: ReduxState): string | undefined => {
  return state.settings.language;
};

export const settingsSelectors = {
  timezone,
  language,
};
