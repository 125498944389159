import { createAction } from "@reduxjs/toolkit";

import { siteReducerSlice } from "./slices";

export type InitializePayload = {
  domain: string;
};

const initialize = createAction<InitializePayload>("site/initialize");

export const siteActions = {
  initialize,
  ...siteReducerSlice.actions,
};
