import { createApi } from "@reduxjs/toolkit/query/react";

import { baseApiQuery } from "./common";
import { addQueryDictToUrl } from "../utils";

export const notesApi = createApi({
  reducerPath: "notesApi",
  baseQuery: baseApiQuery(),
  tagTypes: ["CustomerNotes"],
  endpoints: (builder) => ({
    getCustomerNotes: builder.query<ListResponse<any>, Record<string, any> | void>({
      query: (queryParams) => addQueryDictToUrl(`/api/providers-customers/providers/notes`, queryParams),
      providesTags: ["CustomerNotes"],
    }),

    addCustomerNote: builder.mutation<any, string>({
      query: (data) => ({
        url: `/api/providers-customers/providers/notes`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["CustomerNotes"],
    }),
    deleteCustomerNote: builder.mutation<string, void>({
      query: (note_id) => ({
        url: `/api/providers-customers/providers/notes/${note_id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["CustomerNotes"],
    }),
  }),
});
export const {
  useGetCustomerNotesQuery,
  useLazyGetCustomerNotesQuery,
  useDeleteCustomerNoteMutation,
  useAddCustomerNoteMutation,
} = notesApi;
