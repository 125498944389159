import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TrackingConfig, TrackingGA, TrackingState } from "./types";

const getInitialState = (): TrackingState => {
  return {
    config: {
      googleAnalytics: undefined,
    },
  };
};

export const trackingReducerSlice = createSlice({
  name: "tracking",
  initialState: getInitialState(),
  reducers: {
    setConfig(state, action: PayloadAction<TrackingConfig>) {
      state.config = action.payload;
    },
  },
});
