import { PayloadAction } from "@reduxjs/toolkit";
import { put, takeLatest } from "redux-saga/effects";

import { themeActions } from "./actions";
import { ThemeStatus } from "./types";
import { siteActions } from "../site/actions";
import { SiteConfig } from "../site/types";

function* onSiteSetCodeWithConfig(action: PayloadAction<{ config: SiteConfig }>): Generator {
  const themeConfig = action.payload.config?.themeConfig;
  if (themeConfig) {
    yield put(themeActions.setConfig(themeConfig));
    yield put(themeActions.setStatus(ThemeStatus.READY));
  }
}

export default function* sagas(): Generator {
  yield takeLatest(siteActions.setCodeWithConfig, onSiteSetCodeWithConfig);
}
