import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ChatConfig, ChatState, ChatStatus } from "./types";

const getInitialState = (): ChatState => {
  return {
    status: ChatStatus.NA,
    config: undefined,
    channel_id: undefined,
    unreadChannelMessagesCount: undefined,
  };
};

export const chatReducerSlice = createSlice({
  name: "chat",
  initialState: getInitialState(),
  reducers: {
    setStatus(state, action: PayloadAction<ChatStatus>) {
      state.status = action.payload;
    },
    setConfig(state, action: PayloadAction<ChatConfig>) {
      state.config = action.payload;
    },
    setChannelId(state, action: PayloadAction<string | undefined>) {
      state.channel_id = action.payload;
    },
    setUnreadChannelMessagesCount(state, action: PayloadAction<number>) {
      state.unreadChannelMessagesCount = action.payload;
    },
  },
});
