import LoadingIndicator from "@/components/generic/ui/LoadingIndicator";

export const AppLoading: React.FC = () => {
  return (
    <div className="flex flex-col min-h-full">
      <main className="flex flex-1 overflow-x-hidden overflow-y-auto min-h-full min-w-full">
        <div className="flex flex-col items-center justify-center m-auto w-full max-w-xl text-center p-6">
          <LoadingIndicator />
        </div>
      </main>
    </div>
  );
};
