import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

export const staticPrefix: {
  (src: string): string;
  <T>(src: T): T;
} = (src: any) => {
  if (publicRuntimeConfig.ASSETS_PREFIX) {
    if (typeof src === "string" && src.startsWith("/static/")) {
      return `${publicRuntimeConfig.ASSETS_PREFIX}${src}`;
    }
  }
  return src;
};
