import { createAction } from "@reduxjs/toolkit";

import { chatReducerSlice } from "./slices";

const initialize = createAction("chat/initialize");
const logout = createAction("chat/logout");

export const chatActions = {
  initialize,
  logout,
  ...chatReducerSlice.actions,
};
