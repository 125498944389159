import { createAction } from "@reduxjs/toolkit";

import { onboardingReducerSlice } from "./slices";

const initialize = createAction("onboarding/initialize");

export const onboardingActions = {
  initialize,
  ...onboardingReducerSlice.actions,
};
