import dynamic from "next/dynamic";

const GoogleAnalyticsDynamic = dynamic(() => import("./AppTracking/GoogleAnalytics"), {
  ssr: false,
  loading: () => null,
});

const AppTracking = () => {
  return <GoogleAnalyticsDynamic />;
};

export default AppTracking;
