import { ReduxState } from "@/store/types";

import { SiteConfig, SiteState, SiteStatus } from "./types";

const self = (state: ReduxState): SiteState => state.site;
const config = (state: ReduxState): SiteConfig => state.site.config;
const code = (state: ReduxState): string | undefined => state.site.code;
const appDomain = (state: ReduxState): string | undefined => state.site.appDomain;

const siteStatus = (state: ReduxState): SiteStatus => {
  return state.site.status;
};

const siteConfig = (state: ReduxState): SiteConfig => {
  return state.site.config;
};

export const siteSelectors = {
  self,
  code,
  config,
  appDomain,
  siteStatus,
  siteConfig,
};
