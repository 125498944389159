import { PayloadAction } from "@reduxjs/toolkit";
import { put, takeLatest } from "redux-saga/effects";

import { siteApi } from "@/core/api/services/site";

import { getErrorStatus } from "@/core/api/utils";
import { InitializePayload, siteActions } from "./actions";
import { SiteStatus } from "./types";
import { callRTKQuery } from "../api/sagas";

function* onInitialize(action: PayloadAction<InitializePayload>): Generator {
  const domain = action.payload.domain;
  try {
    const data: any = yield callRTKQuery(siteApi.endpoints.getSiteByDomain.initiate(domain));
    yield put(
      siteActions.setCodeWithConfig({
        code: data.site.code,
        appDomain: domain,
        config: data.public_config,
      }),
    );
    yield put(siteActions.setStatus(SiteStatus.CONFIGURED));
  } catch (err) {
    if (getErrorStatus(err) == 404) {
      yield put(siteActions.setStatus(SiteStatus.INVALID));
    } else {
      yield put(siteActions.setStatus(SiteStatus.ERROR));
    }
  }
}

export default function* sagas(): Generator {
  yield takeLatest(siteActions.initialize, onInitialize);
}
