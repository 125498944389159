import { put, takeLatest } from "redux-saga/effects";

import { onboardingActions } from "./actions";
import { getDefaultSteps } from "./data";
import { OnboardingStatus } from "./types";

function* onInitialize(): Generator {
  yield put(onboardingActions.setStatus(OnboardingStatus.LOADING));
  yield put(onboardingActions.setSteps(getDefaultSteps()));
  // !FIXME - restore from local storage or server
  yield put(onboardingActions.setStatus(OnboardingStatus.IN_PROGRESS));
}

export default function* sagas(): Generator {
  yield takeLatest(onboardingActions.initialize, onInitialize);
}
