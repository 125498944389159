import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FeaturesConfig, FeaturesProviderSelection, FeaturesState } from "./types";

const getInitialState = (): FeaturesState => {
  return {
    config: {
      providerSelection: FeaturesProviderSelection.AUTOMATED,
      providerUserChangeAllowed: false,
    },
  };
};

export const featuresReducerSlice = createSlice({
  name: "features",
  initialState: getInitialState(),
  reducers: {
    setConfig: (state, action: PayloadAction<FeaturesConfig>) => {
      state.config = action.payload;
    },
  },
});
