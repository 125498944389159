import { PayloadAction } from "@reduxjs/toolkit";
import { put, select, takeLatest } from "redux-saga/effects";
import * as Sentry from "@sentry/browser";

import { isClient } from "@/core/utils/nextjs";
import { trackingActions } from "./actions";
import { TrackingConfig } from "./types";
import { siteActions } from "../site/actions";
import { SiteConfig } from "../site/types";
import { AppStatus } from "../app/types";
import { authSelectors } from "../auth/selectors";
import { User } from "../auth/types";
import { appActions } from "../app/actions";
import { authActions } from "../auth/actions";

function* onSiteSetCodeWithConfig(action: PayloadAction<{ config: SiteConfig }>): Generator {
  const tracking = action.payload.config?.tracking;
  if (tracking) {
    yield put(trackingActions.setConfig(tracking as TrackingConfig));
  }
}

function* onAppSetStatus(action: PayloadAction<AppStatus>): Generator {
  if (!isClient()) return;
  if (action.payload === AppStatus.READY_FULL) {
    const user = (yield select(authSelectors.user)) as User | undefined;
    if (user?.id) {
      Sentry.setUser({ customer_id: user.id, type: "customer", name: user.name });
    }
  }
}

function* onAuthSetUser(action: PayloadAction<User>): Generator {
  if (!isClient()) return;
  const user = action.payload;
  if (user?.id) {
    Sentry.setUser({ customer_id: user.id, type: "customer", name: user.name });
  }
}

function* onAuthLogout(action: PayloadAction): Generator {
  if (!isClient()) return;
  Sentry.setUser(null);
}

export default function* sagas(): Generator {
  yield takeLatest(siteActions.setCodeWithConfig, onSiteSetCodeWithConfig);
  yield takeLatest(appActions.setStatus, onAppSetStatus);
  yield takeLatest(authActions.setUser, onAuthSetUser);
  yield takeLatest(authActions.logout, onAuthLogout);
}
