import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getConfig from "next/config";

const supportedLanguages = ["ro"];
const { publicRuntimeConfig } = getConfig();

export const i18nApi = createApi({
  reducerPath: "i18nApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/i18n",
  }),
  tagTypes: ["Languages"],
  endpoints: (builder) => ({
    getLanguage: builder.query<any, string>({
      query: (language) => {
        console.log("language", publicRuntimeConfig.ENVIRONMENT);
        if (!supportedLanguages.includes(language)) {
          language = "ro";
        }
        const buildId =
          publicRuntimeConfig.ENVIRONMENT != "local"
            ? publicRuntimeConfig.BUILD_ID
            : Math.random().toString(36).substring(7);
        return {
          url: `/messages/${language}.json?build=${buildId}`,
        };
      },
      providesTags: ["Languages"],
    }),
  }),
});
export const { useGetLanguageQuery, useLazyGetLanguageQuery } = i18nApi;
