import { createApi, MutationDefinition } from "@reduxjs/toolkit/query/react";

import { baseApiQuery } from "./common";

export const profileApi = createApi({
  reducerPath: "profileApi",
  baseQuery: baseApiQuery(),
  tagTypes: [
    "ProfileInfo",
    "AvailableSpecializations",
    "ProfileSpecializations",
    "AvailableDomainAreas",
    "ProfileDomainAreas",
    "ProfileStudies",
  ],
  endpoints: (builder) => ({
    getProfileInfo: builder.query<any, void>({
      query: () => `/api/providers-profiles/providers/profile`,
      providesTags: ["ProfileInfo"],
    }),
    updateProfileInfo: builder.mutation<any, any>({
      query: (data) => {
        const mutation = {
          url: `/api/providers-profiles/providers/profile`,
          method: "PUT",
          body: data,
          formData: false,
        };

        if (data.avatar_change) {
          const formData = new FormData();
          formData.append("avatar_change", data.avatar_change[0]);
          Object.keys(data).forEach((key) => {
            if (key !== "avatar_change") {
              formData.append(key, data[key]);
            }
          });
          mutation.body = formData;
          mutation.formData = true;
        }

        return mutation;
      },
      invalidatesTags: ["ProfileInfo"],
    }),
    getAvailableSpecializations: builder.query<any, void>({
      query: () => `/api/providers-profiles/public/available-specializations`,
      providesTags: ["AvailableSpecializations"],
    }),
    getProfileSpecializations: builder.query<any, void>({
      query: () => `/api/providers-profiles/providers/specializations`,
      providesTags: ["ProfileSpecializations"],
    }),
    updateProfileSpecializations: builder.mutation<any, any>({
      query: (data) => ({
        url: `/api/providers-profiles/providers/specializations`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["ProfileSpecializations"],
    }),
    getAvailableDomainAreas: builder.query<any, void>({
      query: () => `/api/providers-profiles/public/available-domain-areas`,
      providesTags: ["AvailableDomainAreas"],
    }),
    getProfileDomainAreas: builder.query<any, void>({
      query: () => `/api/providers-profiles/providers/domain-areas`,
      providesTags: ["ProfileDomainAreas"],
    }),
    updateProfileDomainAreas: builder.mutation<any, any>({
      query: (data) => ({
        url: `/api/providers-profiles/providers/domain-areas`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["ProfileDomainAreas"],
    }),
    getProfileStudies: builder.query<any, void>({
      query: () => `/api/providers-profiles/providers/studies`,
      providesTags: ["ProfileStudies"],
    }),
    updateProfileStudies: builder.mutation<any, any>({
      query: (data) => ({
        url: `/api/providers-profiles/providers/studies`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["ProfileStudies"],
    }),
  }),
});
export const {
  useGetProfileInfoQuery,
  useUpdateProfileInfoMutation,
  useGetAvailableSpecializationsQuery,
  useGetProfileSpecializationsQuery,
  useUpdateProfileSpecializationsMutation,
  useGetAvailableDomainAreasQuery,
  useGetProfileDomainAreasQuery,
  useUpdateProfileDomainAreasMutation,
  useGetProfileStudiesQuery,
  useUpdateProfileStudiesMutation,
} = profileApi;
