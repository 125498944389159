import { createApi } from "@reduxjs/toolkit/query/react";

import { baseApiQuery } from "./common";

export const helpApi = createApi({
  reducerPath: "helpapi",
  baseQuery: baseApiQuery(),
  endpoints: (builder) => ({
    getHelpCategories: builder.query<any, void>({
      query: () => `/api/help/faq/categories`,
    }),
    getHelpTopArticles: builder.query<any, void>({
      query: () => `/api/help/faq/articles?limit=5`,
    }),
    getHelpCategoryArticles: builder.query<any, string>({
      query: (id) => `/api/help/faq/articles?category_id=${id}`,
    }),
    getHelpArticle: builder.query<any, string>({
      query: (slug) => `/api/help/faq/articles/${slug}`,
    }),
    getHelpCategory: builder.query<any, string>({
      query: (slug) => `/api/help/faq/categories/${slug}`,
    }),
  }),
});
export const {
  useGetHelpCategoriesQuery,
  useGetHelpTopArticlesQuery,
  useGetHelpArticleQuery,
  useGetHelpCategoryQuery,
  useGetHelpCategoryArticlesQuery,
} = helpApi;
