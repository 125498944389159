import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { OnboardingState, OnboardingStatus, Step } from "./types";

const getInitialState = (): OnboardingState => {
  return {
    status: OnboardingStatus.NA,
    steps: [],
  };
};

export const onboardingReducerSlice = createSlice({
  name: "onboarding",
  initialState: getInitialState(),
  reducers: {
    setStatus: (state, action: PayloadAction<OnboardingStatus>) => {
      state.status = action.payload;
    },
    setSteps: (state, action: PayloadAction<Step[]>) => {
      state.steps = action.payload;
    },
  },
});
