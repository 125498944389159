import { createSelector } from "@reduxjs/toolkit";
import { ReduxState } from "@/store/types";

import { ThemeConfig, ThemeState, ThemeStatus } from "./types";

const self = (state: ReduxState): ThemeState => state.theme;
const status = (state: ReduxState): ThemeStatus => state.theme.status;
const config = (state: ReduxState): ThemeConfig => state.theme.config;
export const themeSelectors = {
  self,
  status,
  config,
};
