import { ReduxState } from "@/store/types";

import { AppStatus } from "./types";

const appStatus = (state: ReduxState): AppStatus => {
  return state.app.status;
};

export const appSelectors = {
  appStatus,
};
