import { createAction } from "@reduxjs/toolkit";

import { appReducerSlice } from "./slices";

export type InitializeBasePayload = {
  endSaga: boolean;
  hostname: string;
};

export type ReInitializePayload = {
  redirect?: string;
};

const initializeBase = createAction<InitializeBasePayload>("app/initializeBase");
const reInitialize = createAction<ReInitializePayload>("app/reInitialize");
const initializeFull = createAction("app/initializeFull");

export const appActions = {
  initializeBase,
  initializeFull,
  reInitialize,
  ...appReducerSlice.actions,
};
