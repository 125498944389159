import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppState, AppStatus } from "./types";

const getInitialState = (): AppState => {
  return {
    status: AppStatus.LOADING,
  };
};

export const appReducerSlice = createSlice({
  name: "app",
  initialState: getInitialState(),
  reducers: {
    setStatus: (state, action: PayloadAction<AppStatus>) => {
      state.status = action.payload;
    },
  },
});
