import { put, select, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { isClient } from "@/core/utils/nextjs";
import { areTimezonesDifferent, getClientTimezone } from "@/core/utils/date/formatting";
import { actionItemsActions } from "./actions";
import { settingsActions } from "../settings/actions";
import { settingsSelectors } from "../settings/selectors";
import { authSelectors } from "../auth/selectors";
import { User, UserStatusType } from "../auth/types";
import { authActions } from "../auth/actions";
import { UserPreference } from "../auth/types";

function* onInitialize(): Generator {
  // Make necesary calls to the backend
  yield checkAccountStatus();
}

function* checkAccountStatus(): Generator {
  if (isClient()) {
    const user = (yield select(authSelectors.user)) as User;
    yield put(actionItemsActions.setIsPendingApproval(user.status == UserStatusType.PENDING_APPROVAL));
  }
}

function* checkDifferentTimezones(): Generator {
  if (isClient()) {
    const userTimezone = (yield select(settingsSelectors.timezone)) as string;
    const timezone = getClientTimezone();
    if (userTimezone && areTimezonesDifferent(userTimezone, timezone)) {
      yield put(actionItemsActions.setTimezoneMismatch(true));
      console.warn(`Timezone mismatch: ${userTimezone} !== ${timezone}`);
    } else {
      yield put(actionItemsActions.setTimezoneMismatch(false));
    }
  }
}

function* onSetUserPreferences(action: PayloadAction<UserPreference | undefined>): Generator {
  const userPreference = action.payload;
  if (userPreference && !userPreference.timezone) {
    yield put(actionItemsActions.setHasMissingTimezone(true));
  } else {
    yield put(actionItemsActions.setHasMissingTimezone(false));
  }
}

export default function* sagas(): Generator {
  yield takeLatest(actionItemsActions.initialize, onInitialize);
  yield takeLatest(settingsActions.setDefaultTimezone, checkDifferentTimezones);
  yield takeLatest(settingsActions.setTimezone, checkDifferentTimezones);
  yield takeLatest(authActions.setUser, checkAccountStatus);
  yield takeLatest(authActions.setUserPreference, onSetUserPreferences);
}
