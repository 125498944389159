export interface AppState {
  status: AppStatus;
}

export enum AppStatus {
  LOADING = "LOADING",
  READY_FULL = "READY_FULL",
  READY_PARTIAL = "READY_PARTIAL",
  ERROR = "ERROR",
}
