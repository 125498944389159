import { NextPageContext } from "next/types";

export const redirectToUrl = (url: string, context: NextPageContext | undefined) => {
  if (context?.res) {
    context.res.writeHead(302, { Location: url });
    context.res.end();
  } else {
    window.location.href = url;
  }
};

export const getHostname = (context: NextPageContext | undefined): string => {
  if (context?.req) {
    return context.req.headers.host || "";
  } else {
    return window.location.hostname;
  }
};
