import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Language, SettingsState } from "./types";

const getInitialState = (): SettingsState => {
  return {
    language: Language.RO,
    timezoneMismatch: false,
    timezone: undefined,
  };
};

export const settingsReducerSlice = createSlice({
  name: "settings",
  initialState: getInitialState(),
  reducers: {
    setDefaultLanguage: (state, action: PayloadAction<Language>) => {
      if (!state.language) {
        state.language = action.payload;
      }
    },
    setDefaultTimezone: (state, action: PayloadAction<string>) => {
      if (!state.timezone) {
        state.timezone = action.payload;
      }
    },
    setLanguage: (state, action: PayloadAction<Language>) => {
      state.language = action.payload;
    },
    setTimezone: (state, action: PayloadAction<string>) => {
      state.timezone = action.payload;
    },
    settimezoneMismatch: (state, action: PayloadAction<boolean>) => {
      state.timezoneMismatch = action.payload;
    },
  },
});
