import { createSelector } from "@reduxjs/toolkit";

import { ReduxState } from "@/store/types";

import { OnboardingProgressState, QuestionsAnswer, QuestionsAnswersMap } from "./types";
import { onboardingSelectors } from "../onboarding/selectors";
import { Step } from "../onboarding/types";

const self = (state: ReduxState): OnboardingProgressState => state.onboardingProgress;
const answersMap = (state: ReduxState): QuestionsAnswersMap => state.onboardingProgress.answersMap;
const stepSlug = (state: ReduxState): string | undefined => state.onboardingProgress.stepSlug;

const step = createSelector(onboardingSelectors.steps, stepSlug, (_steps, _stepSlug): any | undefined => {
  if (_stepSlug === undefined) {
    return _steps.length > 0 ? _steps[0] : undefined;
  }
  return _steps.find((step) => step.slug === _stepSlug);
});

const answersMapState = createSelector(answersMap, (_answersMap): object => {
  const result: Record<string, any> = {};
  for (const key in _answersMap) {
    if (_answersMap.hasOwnProperty(key)) {
      const answer = _answersMap[key];
      if (answer?.isValid) {
        result[key] = answer.values;
      }
    }
  }
  return result;
});

const stepIndex = createSelector(step, onboardingSelectors.steps, (_step, _steps) => {
  if (_step === undefined) {
    return 0;
  }
  const index = _steps.findIndex((q: Step) => q.slug === _step.slug);
  if (index === -1) {
    return 0;
  }
  return index;
});

const previousStep = createSelector(stepIndex, onboardingSelectors.steps, (_index, _steps): Step | undefined => {
  if (_index === undefined) {
    return undefined;
  }
  if (_index === 0) {
    return undefined;
  }
  return _steps[_index - 1];
});

const nextStep = createSelector(stepIndex, onboardingSelectors.steps, (_index, _steps): Step | undefined => {
  if (_index === undefined) {
    return undefined;
  }
  if (_index === _steps.length - 1) {
    return undefined;
  }
  return _steps[_index + 1];
});

const progressPercent = createSelector(stepIndex, onboardingSelectors.steps, (_index, _steps): number => {
  return _steps.length > 0 ? Math.round((_index / _steps.length) * 100) : 0;
});

const stepAnswer = createSelector(step, answersMap, (step, answersMap): QuestionsAnswer | undefined => {
  if (step === undefined) {
    return undefined;
  }
  return answersMap[step.slug];
});

const stepValid = createSelector(step, stepAnswer, (_step, _answer): boolean => {
  return _answer?.isValid ?? false;
});

const stepCompleted = createSelector(step, stepValid, (_step, _stepValid): boolean => {
  return !_step.question.required || _stepValid;
});

const completed = createSelector(onboardingSelectors.steps, answersMap, (_steps, _answersMap): boolean => {
  return _steps.every((step) => {
    const answer = _answersMap[step.slug];
    return answer?.isValid ?? false;
  });
});

export const firstIncompleteStep = createSelector(
  onboardingSelectors.steps,
  answersMap,
  (_steps, _answersMap): Step | undefined => {
    for (let i = 0; i < _steps.length; i++) {
      const step = _steps[i];
      const answer = _answersMap[step.slug];
      if (step?.question?.required && !answer?.isValid) {
        return step;
      }
    }
    return undefined;
  },
);

export const onboardingProgressSelectors = {
  self,
  answersMap,
  answersMapState,
  stepSlug,
  step,
  stepIndex,
  previousStep,
  nextStep,
  progressPercent,
  stepCompleted,
  stepAnswer,
  stepValid,
  completed,
  firstIncompleteStep,
};
