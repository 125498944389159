import { delay, put, race } from "redux-saga/effects";

import { apis } from "@/core/api/services";

const defaultTimeout = 10 * 1000;

export function* callRTKQuery<T>(action: any, ttl = defaultTimeout): Generator<any, T, any> {
  const promise = yield put(action);
  const raced: any = yield race({
    data: yield promise.unwrap(),
    timeout: delay(ttl), // 10s
  });
  if (raced.timeout) {
    throw new Error("timeout on callRTKQuery");
  }
  return raced.data;
}

// function* callRTKQuery2(): Generator {
// Listen for getActiveRunningBooking changes
// const state = yield select();
// agreementsApi.endpoints.getAgreementsUnsignedCount.initiate(undefined, {
//   subscribe: false,
// })(console.log, () => state);
// }

export function* resetApiState(): Generator {
  // reset all RTK Query states
  for (const api of apis) {
    yield put(api.util.resetApiState());
  }
}
