import { createApi } from "@reduxjs/toolkit/query/react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";

import { baseApiQuery } from "./common";
import { addQueryDictToUrl } from "../utils";

dayjs.extend(duration);
dayjs.extend(relativeTime);

export const bookingsApi = createApi({
  reducerPath: "bookingsApi",
  baseQuery: baseApiQuery(),
  tagTypes: ["Stats", "Bookings", "Slots"],
  endpoints: (builder) => ({
    getSlots: builder.query<ListResponse<any>, Record<string, any> | void>({
      query: (queryParams) => addQueryDictToUrl(`/api/providers-customers-bookings/providers/slots`, queryParams),
      providesTags: ["Slots"],
    }),
    getSlotsStats: builder.query<any, void>({
      query: () => `/api/providers-customers-bookings/providers/slots/stats`,
      providesTags: ["Stats"],
    }),
    deleteSlot: builder.mutation<any, any>({
      query: ({ slot_id }) => ({
        url: `/api/providers-customers-bookings/providers/slots/${slot_id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Slots"],
    }),

    cancelCustomerBookingSlot: builder.mutation<any, any>({
      query: ({ slot_id }) => ({
        url: `/api/providers-customers-bookings/providers/slots/${slot_id}/cancel-booking`,
        method: "POST",
      }),
      invalidatesTags: ["Slots"],
    }),
    changeCustomerBookingSlot: builder.mutation<any, any>({
      query: ({ slot_id, ...data }) => ({
        url: `/api/providers-customers-bookings/providers/slots/${slot_id}/change-booking`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Slots"],
    }),
    createAvailabilityRanges: builder.mutation<any, string>({
      query: (data) => ({
        url: `/api/providers-customers-bookings/providers/slots/create-for-ranges`,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["Slots"],
    }),
    cleanupAvailabilityRange: builder.mutation<any, string>({
      query: (data) => ({
        url: `/api/providers-customers-bookings/providers/slots/cleanup-range`,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["Slots"],
    }),
    createCustomerSlotProposal: builder.mutation<any, string>({
      query: (data) => ({
        url: `/api/providers-customers-bookings/providers/slots/create-customer-proposal`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Slots", "Slots"],
    }),
    cancelSlotProposal: builder.mutation<any, any>({
      query: ({ slot_id }) => ({
        url: `/api/providers-customers-bookings/providers/slots/${slot_id}/cancel-customer-proposal`,
        method: "POST",
      }),
      invalidatesTags: ["Slots"],
    }),
  }),
});
export const {
  useDeleteSlotMutation,
  useCancelCustomerBookingSlotMutation,
  useChangeCustomerBookingSlotMutation,
  useGetSlotsStatsQuery,
  useCleanupAvailabilityRangeMutation,
  useGetSlotsQuery,
  useLazyGetSlotsQuery,
  useCreateAvailabilityRangesMutation,
  useCancelSlotProposalMutation,
  useCreateCustomerSlotProposalMutation,
} = bookingsApi;
