import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { OnboardingProgressState, QuestionsAnswer, QuestionsAnswersMap } from "./types";

const getInitialState = (): OnboardingProgressState => {
  return {
    answersMap: {},
    stepSlug: undefined,
  };
};
export const onboardingProgressReducerSlice = createSlice({
  name: "onboardingProgress",
  initialState: getInitialState(),
  reducers: {
    reset: (state) => {
      state.answersMap = {};
      state.stepSlug = undefined;
    },
    setStepSlug: (state, action: PayloadAction<string | undefined>) => {
      state.stepSlug = action.payload;
    },
    setAnswer: (state, action: PayloadAction<{ questionId: string; answer: QuestionsAnswer }>) => {
      const { questionId, answer } = action.payload;
      state.answersMap = {
        ...state.answersMap,
        [questionId]: answer,
      };
    },
    setAnswersMap: (state, action: PayloadAction<QuestionsAnswersMap>) => {
      state.answersMap = action.payload;
    },
  },
});
