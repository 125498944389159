import { accountApi } from "./account";
import { agreementsApi } from "./agreements";
import { authApi } from "./auth";
import { bookingsApi } from "./bookings";
import { chatApi } from "./chat";
import { helpApi } from "./help";
import { pagesApi } from "./pages";
import { customersApi } from "./customers";
import { salesApi } from "./sales";
import { siteApi } from "./site";
import { profileApi } from "./profile";
import { notesApi } from "./notes";
import { i18nApi } from "./i18n";

export const apis = [
  helpApi,
  accountApi,
  salesApi,
  agreementsApi,
  customersApi,
  bookingsApi,
  authApi,
  chatApi,
  siteApi,
  pagesApi,
  profileApi,
  notesApi,
  i18nApi,
];

export const apiMiddlewares = [
  helpApi.middleware,
  accountApi.middleware,
  salesApi.middleware,
  agreementsApi.middleware,
  customersApi.middleware,
  bookingsApi.middleware,
  authApi.middleware,
  chatApi.middleware,
  siteApi.middleware,
  pagesApi.middleware,
  profileApi.middleware,
  notesApi.middleware,
  i18nApi.middleware,
];

export const apiReducers = {
  [helpApi.reducerPath]: helpApi.reducer,
  [accountApi.reducerPath]: accountApi.reducer,
  [salesApi.reducerPath]: salesApi.reducer,
  [agreementsApi.reducerPath]: agreementsApi.reducer,
  [customersApi.reducerPath]: customersApi.reducer,
  [bookingsApi.reducerPath]: bookingsApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [chatApi.reducerPath]: chatApi.reducer,
  [siteApi.reducerPath]: siteApi.reducer,
  [pagesApi.reducerPath]: pagesApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [notesApi.reducerPath]: notesApi.reducer,
  [i18nApi.reducerPath]: i18nApi.reducer,
};
