export interface SettingsState {
  timezoneMismatch: boolean;
  timezone?: string;
  language: Language;
}

export enum Language {
  RO = "ro",
  EN = "en",
}
