import { FC, PropsWithChildren } from "react";
import { useSelector } from "react-redux";

import { themeSelectors } from "@/core/store/redux/theme/selectors";
import { AppFonts } from "./AppTheme/AppFonts";
import { AppStyles } from "./AppTheme/AppStyles";

const AppTheme: FC<PropsWithChildren> = ({ children }) => {
  const themeConfig = useSelector(themeSelectors.config);
  return (
    <>
      <AppFonts themeConfig={themeConfig} />
      <AppStyles themeConfig={themeConfig} />
      {children}
    </>
  );
};

export default AppTheme;
