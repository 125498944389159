import { ALL_AVAILABLE_TIMEZONES_CHOICES } from "@/core/constants";
import { combined, hasLength, isNumeric } from "@/core/utils/forms/validators";
import { OnboardingQuestionType, OnboardingStepType, Step } from "./types";

// !FIXME - this needs to be moved to the Backend

export const getDefaultSteps = (): Step[] => {
  return [
    {
      id: "static",
      slug: "static",
      type: OnboardingStepType.STATIC,
      title: "Creează un cont de specialist",
      description: `
      Platforma îți oferă un sistem complet de management ușor al activității tale, de la prezența online și gestionarea programărilor, până la procesarea plăților și susținerea ședințelor online, astfel încât să fii accesibil/ă clienților din întreaga lume. Creează rapid un cont de profesionist acreditat pentru a începe!
      `,
      image: "/static/global/images/customer-support-man.png",
      action: {
        next: {
          title: "Începe acum!",
        },
      },
    },
    {
      id: "consent",
      slug: "consent",
      type: OnboardingStepType.CONSENT,
      title: "Termeni și condiții",
      description: "Am citit termenii și condițiile. Bifează pentru a merge la urmatorul pas!",
      image: "/static/global/images/secure-consent.png",
      question: {
        required: true,
      },
    },
    {
      id: "name",
      slug: "name",
      type: OnboardingStepType.QUESTION,
      title: "Hai să ne cunoaștem!",
      question: {
        type: OnboardingQuestionType.TEXT,
        label: "Introdu numele tău mai jos",
        required: true,
        placeholder: "Nume..",
        validators: [hasLength(3, 50)],
      },
    },
    {
      id: "phone-number",
      slug: "phone-number",
      type: OnboardingStepType.QUESTION,
      title: "Introdu numărul de telefon",
      question: {
        type: OnboardingQuestionType.TEXT,
        label: "Este necesar pentru a te putea contacta",
        required: true,
        validators: [hasLength(6, 25)],
      },
    },
    {
      id: "timezone",
      slug: "timezone",
      type: OnboardingStepType.TIMEZONE,
      title: "Din ce țară vei susține ședințele online?",
      description:
        "Avem nevoie să știm fusul orar pentru a transmite corect programările către tine și către clienți, ora programărilor va fi ora ta locală. Selectează țara unde ești acum!",
      question: {
        required: true,
        answers: ALL_AVAILABLE_TIMEZONES_CHOICES,
      },
    },
    {
      id: "location-preference",
      slug: "location-preference",
      type: OnboardingStepType.QUESTION,
      title: "Preferi să oferi ședințele:",
      question: {
        type: OnboardingQuestionType.CHECKBOX,
        required: true,
        answers: [
          {
            label: "Fizic în cabinet",
            value: "Fizic în cabinet",
          },
          {
            label: "Online",
            value: "Online",
          },
          {
            label: "Ambele",
            value: "Ambele",
          },
        ],
      },
    },
    {
      id: "practice-certification",
      slug: "practice-certification",
      type: OnboardingStepType.QUESTION,
      title: "Deții un atestat de liberă practică valid?",
      question: {
        type: OnboardingQuestionType.RADIO,
        required: true,
        autoNext: true,
        answers: [
          {
            label: "Da",
            value: "da",
          },
          {
            label: "Nu",
            value: "nu",
          },
        ],
      },
    },
    {
      id: "other-platforms",
      slug: "other-platforms",
      type: OnboardingStepType.QUESTION,
      title: "Mai ești înregistrat/ă pe altă platformă?",
      question: {
        type: OnboardingQuestionType.RADIO,
        required: true,
        autoNext: false,
        answers: [
          {
            label: "Da",
            value: "da",
          },
          {
            label: "Nu",
            value: "nu",
          },
        ],
      },
      followUpQuestion: {
        id: "platform-details",
        type: OnboardingQuestionType.TEXTAREA,
        label: "Introdu platformele pe care ești activ (opțional).",
        placeholder: "Scrie aici...",
        visibleIf: {
          questionId: "radio3",
          selectedValue: "value 1",
        },
      },
    },
    {
      id: "free-session",
      slug: "free-session",
      type: OnboardingStepType.QUESTION,
      title: "De obicei oferi o ședință de cunoaștere gratuită?",
      description:
        "Înțelegem faptul că timpul fiecăruia este foarte valoros. Ne dorim să știm dacă în cadrul anumitor campanii de marketing viitoare ești de acord să oferi acest beneficiu pentru crearea relației terapeutice cu viitorii clienți. Nu există răspuns corect sau greșit!",
      question: {
        type: OnboardingQuestionType.RADIO,
        required: true,
        autoNext: true,
        answers: [
          {
            label: "Da",
            value: "da",
          },
          {
            label: "Nu",
            value: "nu",
          },
        ],
      },
    },
    {
      id: "gender-preference",
      slug: "gender-preference",
      type: OnboardingStepType.QUESTION,
      title: "Prefer să lucrez mai mult cu:",
      question: {
        type: OnboardingQuestionType.RADIO,
        required: true,
        answers: [
          {
            label: "Nu contează",
            value: "no preference",
          },
          {
            label: "Bărbați",
            value: "male",
          },
          {
            label: "Femei",
            value: "female",
          },
          {
            label: "LGBT",
            value: "lgbt",
          },
        ],
      },
    },
    {
      id: "age-preference",
      slug: "age-preference",
      type: OnboardingStepType.QUESTION,
      title: "Îmi doresc să lucrez mai mult cu clienți ce au vârsta cuprinsă între:",
      question: {
        type: OnboardingQuestionType.RADIO,
        required: true,
        answers: [
          {
            label: "Nu contează",
            value: "no preference",
          },
          {
            label: "18-30 ani",
            value: "18-30",
          },
          {
            label: "30-45 ani",
            value: "30-45",
          },
          {
            label: "45-60+ ani",
            value: "45-60+",
          },
        ],
      },
      action: {
        next: {
          url: "/auth/register",
          title: "Inregistrează-te",
        },
      },
    },
  ];
};
