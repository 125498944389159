import { ThemeConfig } from "@/core/store/redux/theme/types";

type Props = {
  themeConfig: ThemeConfig;
};

export const AppStyles: React.FC<Props> = ({ themeConfig }) => {
  return (
    <>
      <style jsx global>{`
        /* ${themeConfig.themeColor} */
        ${themeConfig.themeCss}
      `}</style>
    </>
  );
};
