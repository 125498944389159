type Props = {
  color?: string;
};
export const Loader = ({ color = "text-primary-600", ...props }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" className={`${color}`}>
      <path
        d="M12.0001 22C17.4211 22 22.0001 17.421 22.0001 12H20.0001C20.0001 16.337 16.3371 20 12.0001 20C7.66312 20 4.00012 16.337 4.00012 12C4.00012 7.664 7.66312 4 12.0001 4V2C6.57912 2 2.00012 6.58 2.00012 12C2.00012 17.421 6.57912 22 12.0001 22Z"
        fill="currentColor"
      />
    </svg>
  );
};
