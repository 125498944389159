export interface ThemeState {
  status: ThemeStatus;
  config: ThemeConfig;
}
export type ThemeFontFamily = "overpass" | "roboto";
export type ThemeConfig = {
  themeColor: string;
  themeCss?: string;
  fontFamily: ThemeFontFamily;
};
export type SiteLinks = {
  contactPageUrl: string;
};
export enum ThemeStatus {
  NA = "N/A",
  READY = "READY",
}
