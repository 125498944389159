export interface OnboardingState {
  status: OnboardingStatus;
  steps: Step[];
}

export type Step = any;

export enum OnboardingStatus {
  NA = "NA",
  LOADING = "LOADING",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
}

export enum OnboardingStepType {
  CONSENT = "consent",
  STATIC = "static",
  QUESTION = "question",
  TIMEZONE = "timezone",
}

export enum OnboardingQuestionType {
  TEXT = "text",
  SELECT = "select",
  RADIO = "radio",
  CHECKBOX = "checkbox",
  TEXTAREA = "textarea",
}
