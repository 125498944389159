import { PayloadAction } from "@reduxjs/toolkit";
import { debounce, put, select, takeLatest } from "redux-saga/effects";

import { onboardingProgressActions } from "./actions";
import { onboardingProgressSelectors } from "./selectors";
import { QuestionsAnswersMap } from "./types";
import { Step } from "../onboarding/types";
import { onboardingActions } from "../onboarding/actions";

function* persistProgress() {
  const answersMap = (yield select(onboardingProgressSelectors.answersMap)) as QuestionsAnswersMap;
  localStorage.setItem("onboardingProgress", JSON.stringify(answersMap));
}

function* onSetSteps(action: PayloadAction<Step[]>) {
  // Restore progress
  try {
    const answersMap = JSON.parse(localStorage.getItem("onboardingProgress") || "{}") as QuestionsAnswersMap;
    yield put(onboardingProgressActions.setStepSlug(undefined));
    yield put(onboardingProgressActions.setAnswersMap(answersMap));
  } catch (e) {
    console.error("Failed to restore progress", e);
  }
}

function* onReset() {
  localStorage.removeItem("onboardingProgress");
}

export default function* sagas(): Generator {
  yield takeLatest(onboardingActions.setSteps, onSetSteps);
  yield takeLatest(onboardingProgressActions.reset, onReset);
  yield debounce(1000, onboardingProgressActions.setAnswer, persistProgress);
}
