import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { authSelectors } from "@/core/store/redux/auth/selectors";
import { useGetSlotsQuery } from "@/core/api/services/bookings";
import { actionItemsActions } from "@/core/store/redux/actionItems/actions";

const AppRTKToReduxConnectorForUser: React.FC = () => {
  const dispatch = useDispatch();
  const topSlots = useGetSlotsQuery(
    {
      locked: true,
      limit: 100,
      in_progress_status: "in_progress,in_future",
      ordering: "-locked_reason,start_at",
    },
    {
      pollingInterval: 1000 * 60 * 5, // 5 minutes
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(() => {
    if (!topSlots.isSuccess) return;
    const inProgress = topSlots?.data?.results?.find(
      (slot: any) => slot.locked_reason == "booking" && slot.in_progress_status === "in_progress",
    );
    if (inProgress) dispatch(actionItemsActions.setActiveBooking({ ...inProgress }));
    else dispatch(actionItemsActions.setActiveBooking(undefined));
  }, [topSlots?.data?.results]);

  return null;
};

export const AppRTKToRedux = () => {
  const user = useSelector(authSelectors.user);
  if (!user) return null;
  return <AppRTKToReduxConnectorForUser />;
};
