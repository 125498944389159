import { ReduxState } from "@/store/types";

import { ChatConfig, ChatStatus } from "./types";

const config = (state: ReduxState): ChatConfig => {
  return state.chat.config;
};

const status = (state: ReduxState): ChatStatus => {
  return state.chat.status;
};

const channelId = (state: ReduxState): string | undefined => {
  return state.chat.channel_id;
};

const unreadChannelMessagesCount = (state: ReduxState): number | undefined => {
  return state.chat.unreadChannelMessagesCount;
};
export const chatSelectors = {
  status,
  config,
  channelId,
  unreadChannelMessagesCount,
};
