import { ReduxState } from "@/store/types";

import { OnboardingState, OnboardingStatus, Step } from "./types";

const self = (state: ReduxState): OnboardingState => state.onboarding;
const status = (state: ReduxState): OnboardingStatus => state.onboarding.status;
const steps = (state: ReduxState): any[] => state.onboarding.steps;

export const onboardingSelectors = {
  self,
  status,
  steps,
};
