import { createAction } from "@reduxjs/toolkit";

import { authReducerSlice } from "./slices";
import { Token } from "./types";

export type InitializePayload = {
  token?: Token;
};
const initialize = createAction<InitializePayload>("auth/initialize");
const refresh = createAction("auth/refresh");
const logout = createAction("auth/logout");

export const authActions = {
  initialize,
  logout,
  refresh,
  ...authReducerSlice.actions,
};
