import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { SiteConfig, SiteState, SiteStatus } from "./types";

const getInitialState = (): SiteState => {
  return {
    status: SiteStatus.NA,
    code: undefined,
    appDomain: undefined,
    config: {
      siteName: "",
      siteDescription: "",
      siteAssetsBase: "/sites/invalid",
    },
  };
};

export const siteReducerSlice = createSlice({
  name: "site",
  initialState: getInitialState(),
  reducers: {
    setStatus(state, action: PayloadAction<SiteStatus>) {
      state.status = action.payload;
    },
    setCodeWithConfig(state, action: PayloadAction<{ code: string; appDomain: string; config: SiteConfig }>) {
      state.code = action.payload.code;
      state.appDomain = action.payload.appDomain;
      state.config = action.payload.config;
    },
  },
});
