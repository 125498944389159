import { Action, Dispatch } from "@reduxjs/toolkit";

import { ReduxStore } from "@/store/types";

export const loggerMiddleware = (store: ReduxStore) => (next: Dispatch) => (action: Action) => {
  // if (action.type == HYDRATE && typeof window !== 'undefined') {
  //   console.log('hydrate', action.payload)
  // }
  // before reducers are executed
  console.log("storeLogger", action.type, action);
  let result = next(action);
  // after reducers are executed
  // console.log('next state', store.getState())
  return result;
};

export const createLoggerMiddleware = () => {
  return loggerMiddleware;
};
