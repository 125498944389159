import { createAction } from "@reduxjs/toolkit";

import { settingsReducerSlice } from "./slices";

export type InitializeBasePayload = {
  endSaga: boolean;
  hostname: string;
};

const initialize = createAction("settings/initialize");

export const settingsActions = {
  initialize,
  ...settingsReducerSlice.actions,
};
