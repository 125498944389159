import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ThemeConfig, ThemeState, ThemeStatus } from "./types";

const getInitialState = (): ThemeState => {
  return {
    status: ThemeStatus.NA,
    config: {
      themeColor: "gray.600",
      fontFamily: "overpass",
      themeCss: `
      :root {
        --sample-css-variable: red;
      }
      `,
    },
  };
};

export const themeReducerSlice = createSlice({
  name: "theme",
  initialState: getInitialState(),
  reducers: {
    setStatus: (state, action: PayloadAction<ThemeStatus>) => {
      state.status = action.payload;
    },

    setConfig: (state, action: PayloadAction<ThemeConfig>) => {
      state.config = {
        ...state.config,
        ...action.payload,
      };
    },
  },
});
