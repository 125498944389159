import { Overpass, Roboto } from "next/font/google";
import { useEffect, useState } from "react";
import { NextFontWithVariable } from "next/dist/compiled/@next/font";
import { ThemeConfig, ThemeFontFamily } from "@/core/store/redux/theme/types";

// Inject the font styles into the DOM and use them later based on the theme configuration.
const overpass = Overpass({
  subsets: ["latin"],
  display: "swap",
  variable: "--display-font",
  weight: "700",
});

// FIXME - implement a dynamic loader for the font
const roboto = Roboto({
  subsets: ["latin"],
  display: "swap",
  variable: "--display-font",
  weight: "700",
});

const SupportedFonts: Record<ThemeFontFamily, NextFontWithVariable> = {
  overpass,
  roboto,
};

type Props = {
  themeConfig: ThemeConfig;
};

export const AppFonts: React.FC<Props> = ({ themeConfig }) => {
  const [prevFontFamily, setPrevFontFamily] = useState<ThemeFontFamily | undefined>(undefined);

  useEffect(() => {
    // const main = document.querySelector("#__main");

    if (prevFontFamily && SupportedFonts[prevFontFamily]) {
      // main?.classList.remove(SupportedFonts[prevFontFamily].variable);
    }

    if (SupportedFonts[themeConfig.fontFamily]) {
      // main?.classList.add(SupportedFonts[themeConfig.fontFamily].variable);
      setPrevFontFamily(themeConfig.fontFamily);
    }
  }, [themeConfig.fontFamily]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {SupportedFonts[themeConfig.fontFamily] && (
        <style jsx global>{`
          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          .h1,
          .h2,
          .h3,
          .h4,
          .h5,
          .h6 {
            font-family: ${SupportedFonts[themeConfig.fontFamily].style.fontFamily};
          }
        `}</style>
      )}
    </>
  );
};
