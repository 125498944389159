import { PayloadAction } from "@reduxjs/toolkit";
import { takeLatest } from "redux-saga/effects";

import { CookieKey, CookieValue, deleteCookie, saveCookie } from "@/core/utils/cookies";
import { isClient } from "@/core/utils/nextjs";

import { contextActions } from "./actions";

function* onSetCookie(action: PayloadAction<{ key: CookieKey; value: CookieValue }>): Generator {
  const { key, value } = action.payload;
  saveCookie(undefined, key, value);
}

function* onDeleteCookie(action: PayloadAction<CookieKey>): Generator {
  const key = action.payload;
  deleteCookie(undefined, key);
}

export default function* sagas(): Generator {
  if (isClient()) {
    yield takeLatest(contextActions.setCookie, onSetCookie);
    yield takeLatest(contextActions.deleteCookie, onDeleteCookie);
  }
}
